import PropTypes from "prop-types";
import React from "react";
import GridTable from "../../../../components/common/GridTable";
import SectionHeader from "../../../../components/common/SectionHeader";
import {BpieDistrictPieSummaryTasks} from "./BpieDistrictPieSummaryTasks";

export const BpieDistrictPieSummaryView = ({
                                               districtPies = [],
                                               handleDistrictPieSectionsRedirect,
                                               handleDownloadDistrictPieReport,
                                           }) => {
    return <>
        {
            districtPies.map((districtPie, index) => {
                // todo update definition of when a district PIE is editable
                return (
                    <div key={index} className={`districtPie-summary`}>
                        <SectionHeader>
                            <h2>{districtPie.dateCovered}</h2>
                        </SectionHeader>
                        <GridTable>
                            <thead>
                            <tr>
                                <th>Task</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <BpieDistrictPieSummaryTasks
                                districtPie={districtPie}
                                handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
                                handleDownloadDistrictPieReport={handleDownloadDistrictPieReport}
                            />
                            </tbody>
                        </GridTable>
                    </div>
                );
            })
        }
    </>;
};

BpieDistrictPieSummaryView.propTypes = {
    districtPies: PropTypes.array.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    handleDownloadDistrictPieReport: PropTypes.func.isRequired
};