import {
    dynamicSort,
    isArrayNullOrEmpty,
    isNullOrUndefined, isNumber,
    isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import {emptyGuid} from "../../../constants/config";
import {createBpieIndicator, createBpieResponse} from "../Assessment/bpieAssessmentFactory";
import {
    DBPIE_ASSESSMENT_STATUS, DefaultDistrictPieActionStepStatus,
    DistrictPieIndicatorType,
    DistrictPieIndicatorTypeList,
    DistrictPieOther
} from "../bpieConstants";

export const createSbpieError = ({
                                     errorMessage,
                                     msidNumber,
                                     principalEmail,
                                     principalFirstName,
                                     principalLastName,
                                     schoolBpieErrorId,
                                     schoolName
                                 } = {}) => (
    {
        errorMessage: errorMessage || "",
        msidNumber: msidNumber || "",
        principalEmail: principalEmail || "",
        principalFirstName: principalFirstName || "",
        principalLastName: principalLastName || "",
        schoolBpieErrorId,
        schoolName: schoolName || ""
    }
);

export const createDbpie = ({
                                assessmentTeam,
                                autoApproveDate,
                                bpieStatus,
                                bpieStatusDescription,
                                canDistrictEdit,
                                canFinAdminEdit,
                                canFinFacilitatorEdit,
                                dateMeetingHeld,
                                dateMeetingHeldOutput,
                                datesCovered,
                                district,
                                districtBpieId,
                                districtContactEmail,
                                districtContactFirstName,
                                districtContactLastName,
                                districtFeedback = "",
                                districtId,
                                districtPies,
                                indicators,
                                nextBpieStatus,
                                nextBpieStatusDescription,
                                responses,
                                userId
                            } = {}, isEditable, isDistrictUser, canSubmit) => {
    const isFinalized = bpieStatus >= DBPIE_ASSESSMENT_STATUS.Finalized.id;
    const hasDistrictFeedback = !isTrimmedStringEmpty(districtFeedback);
    const disableDistrictFeedback = !isDistrictUser || !isEditable;
    const displayDistrictFeedback = (hasDistrictFeedback && !isFinalized) || (isEditable && isDistrictUser);

    return {
        assessmentTeam: assessmentTeam || "",
        autoApproveDate: autoApproveDate || "",
        canDistrictEdit: isNullOrUndefined(canDistrictEdit) ? false : canDistrictEdit,
        canFinAdminEdit: isNullOrUndefined(canFinAdminEdit) ? false : canFinAdminEdit,
        canFinFacilitatorEdit: isNullOrUndefined(canFinFacilitatorEdit) ? true : canFinFacilitatorEdit,
        canSubmit,
        dateMeetingHeld: dateMeetingHeld || "",
        dateMeetingHeldOutput: dateMeetingHeldOutput || "",
        districtBpieStatus: bpieStatus || "",
        districtBpieStatusDescription: bpieStatusDescription || "",
        datesCovered: datesCovered || "",
        disableDistrictFeedback,
        displayDistrictFeedback,
        districtBpieId: districtBpieId || "",
        districtContactEmail: districtContactEmail || "",
        districtContactFirstName: districtContactFirstName || "",
        districtContactLastName: districtContactLastName || "",
        districtFeedback: districtFeedback || "",
        districtId: districtId || "",
        districtName: district || "",
        districtPies: isArrayNullOrEmpty(districtPies) ? [] : districtPies.map(i => createDistrictPie(i)),
        indicators: isArrayNullOrEmpty(indicators) ? [] : indicators.map(i => createBpieIndicator(i)),
        isFinalized,
        isEditable,
        nextDistrictBpieStatus: nextBpieStatus || "",
        nextDistrictBpieStatusDescription: nextBpieStatusDescription || "",
        responses: isArrayNullOrEmpty(responses) ? [] : responses.map(r => createBpieResponse(r)),
        userId: userId || ""
    };
}

export const createDistrictInfoDetails = ({
                                              assessmentTeam,
                                              dateMeetingHeld,
                                              userId
                                          } = {}) => (
    {
        assessmentTeam: assessmentTeam || "",
        dateMeetingHeld: dateMeetingHeld || "",
        userId: userId || ""
    }
);

export const createDistrictPie = ({
                                      dateCovered,
                                      dateMeetingHeld = null,
                                      districtContactEmail = "",
                                      districtContactFirstName = "",
                                      districtContactId = null,
                                      districtContactLastName = "",
                                      districtPieId,
                                      districtPieStartYear = 0,
                                      districtPieTaskStatuses = [],
                                      dpieGoals = [],
                                      dpieProgressMonitorings = [],
                                      dpieYear = 1,
                                      indicators = [],
                                      teamMembers = "",
                                      topSbpieIndicators = []
                                  } = {}) => ({
    dateCovered,
    dateMeetingHeld,
    districtContactEmail,
    districtContactFirstName,
    districtContactId,
    districtContactLastName,
    districtPieId,
    districtPieStartYear,
    districtPieTaskStatuses: isArrayNullOrEmpty(districtPieTaskStatuses) ? [] : districtPieTaskStatuses.map(i => createDistrictPieTaskStatusDetails(i)),
    dpieGoals: isArrayNullOrEmpty(dpieGoals) ? [] : dpieGoals.map(i => createDpieGoal(i)),
    dpieProgressMonitorings: isArrayNullOrEmpty(dpieProgressMonitorings) ? [] : dpieProgressMonitorings.map(i => createDpieProgressMonitoring(i)),
    dpieYear,
    indicators: isArrayNullOrEmpty(indicators) ? [] : indicators.map(i => createIndicatorObject(i)),
    teamMembers,
    topSbpieIndicators: isArrayNullOrEmpty(topSbpieIndicators) ? [] : topSbpieIndicators.map(i => createTopSbpieIndicatorObject(i)),
});

export const createDpieGoal = ({
                                   actionSteps = [],
                                   additionalBpiePriorityIndicator = "",
                                   dbpieIndicators = [],
                                   dpieGoalId = emptyGuid,
                                   goalDescription = "",
                                   goalNumber = null,
                                   indicator5ARegularClassPlacement = false,
                                   indicator5BSeparateClassPlacement = false,
                                   indicator5CSeparateClassEnvironment = false,
                                   otherSppIndicators = "",
                                   sbpieIndicators = [],
                               } = {}) => {
    let sppIndicatorsString = "";
    if (indicator5ARegularClassPlacement)
        sppIndicatorsString += `${DistrictPieIndicatorType.RegularClassPlacement.description}, `;
    if (indicator5BSeparateClassPlacement)
        sppIndicatorsString += `${DistrictPieIndicatorType.SeparateClassPlacement.description}, `;
    if (indicator5CSeparateClassEnvironment)
        sppIndicatorsString += `${DistrictPieIndicatorType.SeparateClassEnvironment.description}, `;
    if (!isTrimmedStringEmpty(otherSppIndicators))
        sppIndicatorsString += `${otherSppIndicators}, `;

    return {
        actionSteps: isArrayNullOrEmpty(actionSteps) ? [] : actionSteps.map(i => createActionStep(i)),
        additionalBpiePriorityIndicator,
        dbpieIndicators: isArrayNullOrEmpty(dbpieIndicators) ? [] : dbpieIndicators.map(i => createSelectedBpiePriorityIndicators(i)),
        dpieGoalId,
        goalDescription,
        goalNumber,
        indicator5ARegularClassPlacement,
        indicator5BSeparateClassPlacement,
        indicator5CSeparateClassEnvironment,
        otherSppIndicators,
        sppIndicatorsString: !isTrimmedStringEmpty(sppIndicatorsString) ? sppIndicatorsString.slice(0, -2) : "",
        sbpieIndicators: isArrayNullOrEmpty(sbpieIndicators) ? [] : sbpieIndicators.map(i => createSelectedBpiePriorityIndicators(i))
    };
};

export const createDpieGoalForm = ({
                                       additionalBpiePriorityIndicator,
                                       dbpieIndicators,
                                       dpieGoalId,
                                       goalDescription,
                                       goalNumber,
                                       indicator5ARegularClassPlacement,
                                       indicator5BSeparateClassPlacement,
                                       indicator5CSeparateClassEnvironment,
                                       otherSppIndicators,
                                       sbpieIndicators
                                   }) => {
    let sppIndicators = [];
    if (indicator5ARegularClassPlacement)
        sppIndicators.push(DistrictPieIndicatorType.RegularClassPlacement.description);
    if (indicator5BSeparateClassPlacement)
        sppIndicators.push(DistrictPieIndicatorType.SeparateClassPlacement.description);
    if (indicator5CSeparateClassEnvironment)
        sppIndicators.push(DistrictPieIndicatorType.SeparateClassEnvironment.description);
    if (!isTrimmedStringEmpty(otherSppIndicators))
        sppIndicators.push(otherSppIndicators);

    return {
        additionalBpiePriorityIndicator,
        priorityIndicatorIds: [...dbpieIndicators.map(i => i.priorityIndicatorId), ...sbpieIndicators.map(i => i.priorityIndicatorId)],
        dpieGoalId,
        goalDescription,
        goalNumber,
        sppIndicators
    };
}

export const createActionStep = ({
                                     actionStepDescription = "",
                                     actionStepId = emptyGuid,
                                     actionStepNumber = 0,
                                     completeByDate = "",
                                     currentActionStepStatus = DefaultDistrictPieActionStepStatus,
                                     evidence = "",
                                     followUp = "",
                                     responsibleForCompletion = ""
                                 } = {}) => ({
    actionStepDescription,
    actionStepId,
    actionStepNumber,
    completeByDate,
    currentActionStepStatus,
    evidence,
    followUp,
    responsibleForCompletion
});

export const createDpieProgressMonitoring = ({
                                                 actionStepUpdates = [],
                                                 dateMeetingHeld = null,
                                                 dateMeetingHeldOutput = "",
                                                 districtContactEmail = "",
                                                 districtContactFirstName = "",
                                                 districtContactId = null,
                                                 districtContactLastName = "",
                                                 dpieProgressMonitoringId = emptyGuid,
                                                 teamMembers = ""
                                             } = {}) => ({
    actionStepUpdates: isArrayNullOrEmpty(actionStepUpdates) ? [] : actionStepUpdates.map(actionStepUpdate => createActionStepUpdate(actionStepUpdate)),
    dateMeetingHeld,
    dateMeetingHeldOutput,
    districtContactEmail,
    districtContactFirstName,
    districtContactId,
    districtContactLastName,
    dpieProgressMonitoringId,
    teamMembers
});

export const createActionStepUpdate = ({
                                           actionStepUpdateNotes = "",
                                           dpieActionStepId = "",
                                           dpieActionStepUpdateId = emptyGuid,
                                           dpieActionStepStatus = ""
                                       } = {}) => ({
    actionStepUpdateNotes,
    dpieActionStepId,
    dpieActionStepUpdateId,
    dpieActionStepStatus
});

export const createActionStepUpdateFormProperties = (actionStepUpdates, actionSteps) => {
    let actionStepUpdatesForm = {};
    for(let actionStep of actionSteps) {
        const actionStepUpdate = actionStepUpdates.find(f => f.dpieActionStepId === actionStep.actionStepId) ?? createActionStepUpdate();
        actionStepUpdatesForm[`dpieActionStepStatus_${actionStep.actionStepId}`] = actionStepUpdate.dpieActionStepStatus;
        actionStepUpdatesForm[`actionStepUpdateNotes_${actionStep.actionStepId}`] = actionStepUpdate.actionStepUpdateNotes;
    }

    return actionStepUpdatesForm;
};

export const createSaveActionStepUpdatesArray = (actionStepUpdatesForm, actionStepUpdates, actionSteps) => {
    let actionStepUpdatesList = [];
    for (let actionStep of actionSteps) {
        const status = actionStepUpdatesForm[`dpieActionStepStatus_${actionStep.actionStepId}`].value;
        const actionStepUpdate = actionStepUpdates.find(f => f.dpieActionStepId === actionStep.actionStepId);
        actionStepUpdatesList.push({
            actionStepUpdateNotes: actionStepUpdatesForm[`actionStepUpdateNotes_${actionStep.actionStepId}`].value,
            dpieActionStepId: actionStep.actionStepId,
            dpieActionStepStatus: isNumber(status) ? parseInt(status) : null,
            dpieActionStepUpdateId: actionStepUpdate?.dpieActionStepUpdateId ?? emptyGuid
        });
    }

    return actionStepUpdatesList;
}

const createSelectedBpiePriorityIndicators = ({
                                                  indicatorNumber = 0,
                                                  indicatorSummary = "",
                                                  priorityIndicatorId = ""
                                              } = {}) => ({
    indicatorNumber,
    indicatorSummary,
    priorityIndicatorId
});

export const createDistrictPieTaskStatusDetails = ({
                                                       dpieTask = 0,
                                                       dpieTaskStatus = 0,
                                                       dpieTaskStatusDescription = "",
                                                       isDisabled = true
                                                   } = {}) => ({
    dpieTask,
    dpieTaskStatus,
    dpieTaskStatusDescription,
    isDisabled
});

export const createIndicatorObject = ({
                                          currentDistrictDataAndSource = "",
                                          districtTarget = "",
                                          dpieIndicatorId = null,
                                          indicatorNumber = 0,
                                          stateTarget = ""
                                      } = {}) => ({
    currentDistrictDataAndSource,
    districtTarget,
    dpieIndicatorId,
    indicatorNumber,
    stateTarget
});

const createIndicatorFormProperties = (indicators = null) => {
    let indicatorFormProperties = {};

    for (let indicatorType of DistrictPieIndicatorTypeList) {
        const currentResponse = indicators?.find(f => f.indicatorNumber === indicatorType.id);

        indicatorFormProperties[`stateTarget_${indicatorType.id}`] = currentResponse?.stateTarget ?? "";
        indicatorFormProperties[`currentDistrictDataAndSource_${indicatorType.id}`] = currentResponse?.currentDistrictDataAndSource ?? "";
        indicatorFormProperties[`districtTarget_${indicatorType.id}`] = currentResponse?.districtTarget ?? "";
    }

    return indicatorFormProperties;
};

export const createDbpiePriorityIndicatorsArray = (districtBpie) => {
    const dbpiePriorityIndicators = districtBpie.responses.filter(f => f.isPriority);

    let indicators = [];
    for (let indicator of dbpiePriorityIndicators) {
        const indicatorDetails = districtBpie.indicators.find(f => f.bpieIndicatorId === indicator.bpieIndicatorId);
        indicators.push({
            bpieIndicatorId: indicatorDetails.bpieIndicatorId,
            indicatorNumber: indicatorDetails.indicatorNumber,
            indicatorSummary: indicatorDetails.summary
        });
    }

    return indicators.sort(dynamicSort("indicatorNumber"));
};

export const createTopSbpieIndicatorObject = ({
                                                  bpieIndicatorId = null,
                                                  dpieTopSbpieIndicatorId = null,
                                                  indicatorNumber = 0,
                                                  indicatorSummary = "",
                                                  percentageDescription = "",
                                                  totalRecords = 0
                                              } = {}) => ({
    bpieIndicatorId,
    dpieTopSbpieIndicatorId,
    indicatorNumber,
    indicatorSummary,
    percentageDescription,
    totalRecords
});

export const createDpieDistrictInfoDetails = ({
                                                  dateMeetingHeld = "",
                                                  districtContactId = "",
                                                  indicators = [],
                                                  teamMembers = "",
                                              } = {}) => {
    const districtInfoDetails = {
        dateMeetingHeld: dateMeetingHeld || "",
        districtContactId: districtContactId || "",
        teamMembers: teamMembers || ""
    };

    return {...districtInfoDetails, ...createIndicatorFormProperties(indicators)};
};

export const createSaveDpieDistrictInfoDetailsObject = (dpieDetailsForm, districtPie) => {
    let indicators = [];

    for (let indicatorType of DistrictPieIndicatorTypeList) {
        const dpieIndicatorId = districtPie.indicators.find(f => f.indicatorNumber === indicatorType.id)?.dpieIndicatorId;

        indicators.push({
            currentDistrictDataAndSource: dpieDetailsForm[`currentDistrictDataAndSource_${indicatorType.id}`].value,
            districtTarget: dpieDetailsForm[`districtTarget_${indicatorType.id}`].value,
            dpieIndicatorId: dpieIndicatorId || emptyGuid,
            indicatorNumber: indicatorType.id,
            stateTarget: dpieDetailsForm[`stateTarget_${indicatorType.id}`].value
        });
    }

    return {
        dateMeetingHeld: dpieDetailsForm.dateMeetingHeld.value,
        districtContactId: dpieDetailsForm.districtContactId.value,
        indicators,
        teamMembers: dpieDetailsForm.teamMembers.value
    };
};

export const createSaveDpieGoalDetailsObject = (dpieGoalDetailsForm) => {
    const otherSppIndicators = dpieGoalDetailsForm.sppIndicators.value.find(f => f.startsWith(DistrictPieOther));

    return {
        additionalBpiePriorityIndicator: dpieGoalDetailsForm.additionalBpiePriorityIndicator.value,
        dpieGoalId: dpieGoalDetailsForm.dpieGoalId.value,
        goalDescription: dpieGoalDetailsForm.goalDescription.value,
        indicator5ARegularClassPlacement: dpieGoalDetailsForm.sppIndicators.value.includes(DistrictPieIndicatorType.RegularClassPlacement.description),
        indicator5BSeparateClassPlacement: dpieGoalDetailsForm.sppIndicators.value.includes(DistrictPieIndicatorType.SeparateClassPlacement.description),
        indicator5CSeparateClassEnvironment: dpieGoalDetailsForm.sppIndicators.value.includes(DistrictPieIndicatorType.SeparateClassEnvironment.description),
        otherSppIndicators: otherSppIndicators ?? "",
        priorityIndicators: dpieGoalDetailsForm.priorityIndicatorIds.value
    };
};