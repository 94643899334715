import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../bpieLocations"

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictPieSummaryApi {
    static downloadDistrictPie(dateWindowId, districtId, districtBpieId, districtPieId, includeStatusUpdates) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/dpies/${districtPieId}/download/${includeStatusUpdates}`,
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictPieSummaryApi {
    static downloadDistrictPie(){
        return mockResolveApiCall({});
    }
}

const bpieDistrictPieSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictPieSummaryApi : MockBpieDistrictPieSummaryApi;
export default bpieDistrictPieSummaryApi;