import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../../components/common/commonUtilities";
import FormTable from "../../../../../components/common/FormTable";
import GridTable from "../../../../../components/common/GridTable";
import {CheckBoxList} from "../../../../../components/common/inputs";
import CheckBoxField from "../../../../../components/common/inputs/CheckBoxField";
import {
    appendedCheckboxOtherValueExists,
    clearInputFormErrorFields,
    inputFormIsValid, useFormInputObject
} from "../../../../../components/common/inputs/inputUtility";
import TextArea from "../../../../../components/common/inputs/TextArea";
import TextAreaEditor from "../../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {TEXT_TYPES} from "../../../../../constants/inputConstants";
import {
    DistrictPieIndicatorTypeListWithOther,
    DistrictPieIndicatorTypeWithTextField, DistrictPieOther
} from "../../../bpieConstants";
import {createDpieGoalForm, createSaveDpieGoalDetailsObject} from "../../bpieDistrictObjectFactory";

export const BpieDistrictPieDetailsGoalForm = ({
                                                   dbpiePriorityIndicators,
                                                   dpieGoal,
                                                   handleCancelDpieGoalDetailsClick,
                                                   handleDataChanged,
                                                   handleSaveDistrictPieGoalDetails,
                                                   isAdd,
                                                   sbpiePriorityIndicators
                                               }) => {
    const dpieGoalDetailsForm = useFormInputObject(createDpieGoalForm(dpieGoal), () => handleDataChanged());

    const handleSaveDpieGoalDetailsClick = (event) => {
        event.preventDefault();
        saveDistrictGoalDetails();
    };

    const saveDistrictGoalDetails = () => {
        const isValid = validateForm();
        if (isValid) {
            const result = createSaveDpieGoalDetailsObject(dpieGoalDetailsForm);
            handleSaveDistrictPieGoalDetails(result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(dpieGoalDetailsForm);

        const otherString = DistrictPieOther;
        if (!appendedCheckboxOtherValueExists(otherString, dpieGoalDetailsForm.sppIndicators.value))
            dpieGoalDetailsForm.sppIndicators.setError(`More details are required when ${otherString} is selected for SPP Indicators.`)

        if (isTrimmedStringEmpty(dpieGoalDetailsForm.goalDescription.value))
            dpieGoalDetailsForm.goalDescription.setError("Required");

        let isValid = inputFormIsValid(dpieGoalDetailsForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const onPriorityIndicatorChange = (event, priorityIndicatorId) => {
        let currentPriorityIndicatorIds = dpieGoalDetailsForm.priorityIndicatorIds.value;

        if(event.target.checked) {
            if(!currentPriorityIndicatorIds.includes(priorityIndicatorId))
                dpieGoalDetailsForm.priorityIndicatorIds.setValue([...currentPriorityIndicatorIds, priorityIndicatorId]);
        }
        else {
            if(currentPriorityIndicatorIds.includes(priorityIndicatorId))
                dpieGoalDetailsForm.priorityIndicatorIds.setValue(currentPriorityIndicatorIds.filter(f => f !== priorityIndicatorId));
        }
    };

    return <>
        <GridTable>
            <thead>
            <tr>
                <th>District BPIE Priority Indicators</th>
            </tr>
            </thead>
            <tbody>
            {
                dbpiePriorityIndicators.map((indicator, key) => {
                    const isChecked = dpieGoalDetailsForm.priorityIndicatorIds.value.some(pi => pi === indicator.bpieIndicatorId);
                    return <tr key={`dbpie_${key}`}>
                        <td>
                            <CheckBoxField
                                name={`cb_${indicator.bpieIndicatorId}`}
                                label={`<strong>Indicator ${indicator.indicatorNumber}:</strong> ${indicator.indicatorSummary}`}
                                showLabel
                                checked={isChecked}
                                onChange={(event) => onPriorityIndicatorChange(event, indicator.bpieIndicatorId)}
                                value={indicator.bpieIndicatorId}
                            />
                        </td>
                    </tr>;
                })
            }
            {
                dbpiePriorityIndicators.length === 0 &&
                <tr>
                    <td>
                        <strong>No District BPIE Priority Indicators have been set.</strong>
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>

        <GridTable>
            <thead>
            <tr>
                <th>Top School BPIE Priority Indicators</th>
            </tr>
            </thead>
            <tbody>
            {
                sbpiePriorityIndicators.map((indicator, key) => {
                    const isChecked = dpieGoalDetailsForm.priorityIndicatorIds.value.some(pi => pi === indicator.bpieIndicatorId);
                    return <tr key={`sbpie_${key}`}>
                        <td>
                            <CheckBoxField
                                name={`cb_${indicator.bpieIndicatorId}`}
                                label={`<strong>Indicator ${indicator.indicatorNumber}:</strong> ${indicator.indicatorSummary} <strong>${indicator.percentageDescription}</strong>`}
                                showLabel
                                checked={isChecked}
                                onChange={(event) => onPriorityIndicatorChange(event, indicator.bpieIndicatorId)}
                                value={indicator.bpieIndicatorId}
                            />

                        </td>
                    </tr>;
                })
            }
            {
                sbpiePriorityIndicators.length === 0 &&
                <tr>
                    <td>
                        <strong>No School BPIE Priority Indicators have been set.</strong>
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>

        <FormTable>
            <TextArea
                {...dpieGoalDetailsForm.additionalBpiePriorityIndicator}
                label={'Additional BPIE Assessment Indicator'}
                showLabel
            />

            <CheckBoxList
                legend={"Please check all SPP Indicators that apply if applicable"}
                listHasTextField={DistrictPieIndicatorTypeWithTextField}
                name={"sppIndicators"}
                options={DistrictPieIndicatorTypeListWithOther}
                showLabel
                textType={TEXT_TYPES.TextField}
                {...dpieGoalDetailsForm.sppIndicators}
            />

            <div>
                <h3>Goal Number: {isAdd ? `Visible on save` : dpieGoalDetailsForm.goalNumber.value}</h3>

                <TextAreaEditor
                    {...dpieGoalDetailsForm.goalDescription}
                    label={'Goal Description'}
                    showLabel
                />
            </div>
        </FormTable>

        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
            <Button
                buttonType={ButtonTypes.SAVE}
                label={"Save Goal Details"}
                name="btnSave"
                onClick={handleSaveDpieGoalDetailsClick}
            />
            <Button
                buttonType={ButtonTypes.CANCEL}
                label={`Cancel`}
                name={`btnCancelDpieGoalDetails`}
                onClick={handleCancelDpieGoalDetailsClick}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieDetailsGoalForm.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    dpieGoal: PropTypes.object.isRequired,
    handleCancelDpieGoalDetailsClick: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveDistrictPieGoalDetails: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    sbpiePriorityIndicators: PropTypes.array.isRequired,
};