import PropTypes from "prop-types";
import React from "react";
import {isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridTable from "../../../components/common/GridTable";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";

export const DpieGoalDetailsInfo = ({
                                        dpieGoal,
                                    }) => {
    const hasDbpieIndicators = !isArrayNullOrEmpty(dpieGoal.dbpieIndicators);
    const hasSbpieIndicators = !isArrayNullOrEmpty(dpieGoal.sbpieIndicators);
    const hasAdditionalBpiePriorityIndicator = !isTrimmedStringEmpty(dpieGoal.additionalBpiePriorityIndicator);
    const hasSppIndicators = !isTrimmedStringEmpty(dpieGoal.sppIndicatorsString);

    const anyIndicatorsSelected = hasDbpieIndicators
        || hasSbpieIndicators
        || hasAdditionalBpiePriorityIndicator
        || hasSppIndicators;

    return <>
        {
            anyIndicatorsSelected &&
            <GridTable>
                <thead>
                <tr>
                    <th>Selected Indicators</th>
                </tr>
                </thead>
                <tbody>
                {
                    hasDbpieIndicators &&
                    <tr>
                        <td>
                            <strong>District BPIE Assessment Priority Indicator(s): </strong>
                            <ul>
                                {
                                    dpieGoal.dbpieIndicators.map((indicator, index) => {
                                        return <li key={`dbpie_${index}`}>
                                            <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                                        </li>;
                                    })
                                }
                            </ul>
                        </td>
                    </tr>
                }
                {
                    hasSbpieIndicators &&
                    <tr>
                        <td>
                            <strong>School BPIE Assessment Priority Indicator(s): </strong>
                            <ul>
                                {
                                    dpieGoal.sbpieIndicators.map((indicator, index) => {
                                        return <li key={`sbpie_${index}`}>
                                            <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                                        </li>;
                                    })
                                }
                            </ul>
                        </td>
                    </tr>
                }
                {
                    hasAdditionalBpiePriorityIndicator &&
                    <tr>
                        <td>
                            <strong>Additional BPIE Assessment
                                Indicator: </strong> {dpieGoal.additionalBpiePriorityIndicator}
                        </td>
                    </tr>
                }
                {
                    hasSppIndicators &&
                    <tr>
                        <td>
                            <strong>SPP Indicators: </strong> {dpieGoal.sppIndicatorsString}
                        </td>
                    </tr>
                }
                </tbody>
            </GridTable>
        }
        <h3>Goal {dpieGoal.goalNumber}</h3>
        <HtmlDisplayBlock html={dpieGoal.goalDescription}/>
    </>;
};

DpieGoalDetailsInfo.propTypes = {
    dpieGoal: PropTypes.object.isRequired
};