import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import {BpieDistrictPieDetailsSetup} from "./BpieDistrictPieDetailsSetup";
import {BpieDistrictPieDetailsForm} from "./BpieDistrictPieDetailsForm";

export const BpieDistrictPieDetailsView = ({
                                               districtContacts,
                                               districtPie,
                                               dbpiePriorityIndicators,
                                               handleDataChanged,
                                               handleDistrictPieSummaryRedirect,
                                               handleSaveDistrictPieDetails,
                                               handleSetupDpie
                                           }) => {

    const hasTopPriorityIndicators = districtPie.topSbpieIndicators.length > 0;
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    return <>
        <BpieDistrictPieDetailsSetup
            handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
            handleSetupDpie={handleSetupDpie}
            hasTopPriorityIndicators={hasTopPriorityIndicators}
            isEditable={isEditable}
            isYearTwoOrThree={districtPie.dpieYear > 1}
        />

        {
            hasTopPriorityIndicators &&
            <BpieDistrictPieDetailsForm
                dbpiePriorityIndicators={dbpiePriorityIndicators}
                districtContacts={districtContacts}
                districtPie={districtPie}
                handleDataChanged={handleDataChanged}
                handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
                handleSaveDistrictPieDetails={handleSaveDistrictPieDetails}
                isEditable={isEditable}
            />
        }
    </>;
};

BpieDistrictPieDetailsView.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtContacts: PropTypes.array,
    districtPie: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    handleSaveDistrictPieDetails: PropTypes.func.isRequired,
    handleSetupDpie: PropTypes.func.isRequired
};