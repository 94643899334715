import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import bpieDistrictPieSummaryApi from "./bpieDistrictPieSummaryApi";
import {BpieDistrictPieSummaryView} from "./BpieDistrictPieSummaryView";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieReportType} from "../../bpieConstants";

export const BpieDistrictPieSummaryContainer = ({
                                                    actions,
                                                    dateWindowId,
                                                    districtBpie,
                                                    districtBpieId,
                                                    districtId,
                                                    handleDistrictBpieSearchRedirect,
                                                    handleDistrictPieSectionsRedirect,
                                                    updatePageTitleDetails
                                                }) => {
    const handleDownloadDistrictPieReport = (districtPieId, reportType) => {
        const includeStatusUpdates = reportType === DistrictPieReportType.WithProgressMonitoringData;
        actions.executeApi(bpieDistrictPieSummaryApi.downloadDistrictPie, [dateWindowId, districtId, districtBpieId, districtPieId, includeStatusUpdates]);
    };

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        updatePageTitleDetails();
    }, [districtBpie]);

    return (
        <BpieDistrictPieSummaryView
            districtPies={districtBpie.districtPies}
            handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
            handleDownloadDistrictPieReport={handleDownloadDistrictPieReport}
        />
    );
}

BpieDistrictPieSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtBpieId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};