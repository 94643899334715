import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const BpieDistrictPieDetailsSetup = ({
                                                handleDistrictPieSummaryRedirect,
                                                handleSetupDpie,
                                                hasTopPriorityIndicators,
                                                isEditable,
                                                isYearTwoOrThree
                                            }) => {
    const yearTwoOrThreeLabel = isYearTwoOrThree ? " and previous DPIE Goals/Actions" : "";

    const handleSetupDpieClick = (event) => {
        event.preventDefault();
        if (confirm(`Are you sure you want to add the Top SBPIE Priority Indicators${yearTwoOrThreeLabel}?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            handleSetupDpie();
    };

    if (hasTopPriorityIndicators) return null;

    const pageDirections = isYearTwoOrThree
        ? "All goals and actions from the previous year will be included. Any SBPIE priority indicators added since last year's PIE will be available as well."
        : "Aggregated top SBPIE Priority Indicators need to be added before moving on. Any SBPIEs not completed yet will not be included.";

    const btnLabel = isYearTwoOrThree
        ? "Import Data and Setup Plan"
        : "Add Top SBPIE Priority Indicators";

    return <>
        <p>{pageDirections}</p>

        <ButtonBar>
            {
                isEditable &&
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={btnLabel}
                    name="btnSetupDpie"
                    onClick={handleSetupDpieClick}
                />
            }
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Summary`}
                name={`btnReturnDpieSummary`}
                onClick={handleDistrictPieSummaryRedirect}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieDetailsSetup.propTypes = {
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    handleSetupDpie: PropTypes.func.isRequired,
    hasTopPriorityIndicators: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isYearTwoOrThree: PropTypes.bool.isRequired
};